<template>
    <v-container class="w-auto bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col cols="12">
                    <!-- <v-img :src="require('../../assets/logo_vue.svg')" class="my-3" contain height="200" /> -->
                    <v-img :src="require('../../assets/logo.jpg')" class="my-3" contain height="200" />
                </v-col>
            </v-row>
            <div class="d-flex flex-column mb-6 bg-surface-variant">
                <v-row no-gutters style="flex-wrap: nowrap;">
                    <v-col>
                        <v-card elevation="2" outlined shaped>
                            <br>
                            <v-row justify="center">
                                <v-card-title class="text-h5">¡Bienvenido a nuestra plataforma líder en bots de
                                    criptomonedas!</v-card-title>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                                <v-col cols="8" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                                    <v-row justify="center">
                                        <p><br>
                                            ¿Estás buscando maximizar tus ganancias en el mercado de criptomonedas de
                                            manera
                                            eficiente y
                                            automatizada?<br>
                                            <br>
                                            ¡Has llegado al lugar correcto! <br>
                                            Nuestra aplicación te ofrece la herramienta para que crees tu estrategia
                                            y aprovechar al máximo cada oportunidad de inversión.<br>

                                            Con nuestra plataforma, puedes personalizar fácilmente tus estrategias de
                                            trading, establecer tus propios parámetros de riesgo y dejar que nuestros
                                            bots inteligentes se encarguen del resto. <br>
                                        </p>
                                        <v-img :src="require('../../assets/pantalla_new_bot.jpg')" class="my-3" contain
                                            height="200" />
                                        <p> Ya sea que seas un inversor novato o un experto en criptomonedas,
                                            nuestra interfaz intuitiva te permite operar con confianza y seguridad.
                                            Nuestros algoritmos avanzados analizan constantemente el mercado en busca de
                                            oportunidades lucrativas, y nuestras estrategias automatizadas te permiten
                                            ejecutar operaciones las 24 horas del día, los 7 días de la semana, sin
                                            perder una sola oportunidad.<br>
                                            Además, nuestra plataforma te proporciona informes detallados y análisis en
                                            tiempo real para que siempre estés al tanto del rendimiento de tus
                                            inversiones.
                                            <br>
                                        </p>
                                        <!-- IMAGEN ORDENES -->
                                        <v-img :src="require('../../assets/pantalla_ordenes.jpg')" class="my-3" contain
                                            height="200" />
                                        <p> En resumen, con nuestra aplicación web de bots de criptomonedas, puedes
                                            llevar tus inversiones al siguiente nivel de manera eficiente, segura y
                                            rentable ya que tus fondos siempre estarán en tu Exchange de confianza.<br>
                                            <br>
                                            <br>
                                        </p>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0"></v-col>
                                <v-col cols="10" xs="12" sm="12" md="10" class="flex-grow-0 flex-shrink-0">
                                    <v-row justify="center">
                                        <v-card class="pa-1" shaped>
                                            <v-card-title class="text-h5">Precios</v-card-title>
                                            <v-simple-table dark dense>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                Tipo
                                                            </th>
                                                            <th class="text-center">
                                                                Periodo
                                                            </th>
                                                            <th class="text-center">
                                                                Bots
                                                            </th>
                                                            <th class="text-center">
                                                                Limite Inversión
                                                            </th>
                                                            <th class="text-center">
                                                                Precio
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in items" :key="item.id">
                                                            <td width="200px" :style="changeBackgroundColor(item.tipo)">
                                                                {{ item.tipo }}</td>
                                                            <td width="150px" :style="changeBackgroundColor(item.tipo)">
                                                                {{ item.periodo }}</td>
                                                            <td width="100px" :style="changeBackgroundColor(item.tipo)">
                                                                {{ item.bots }}
                                                            </td>
                                                            <td width="200px" :style="changeBackgroundColor(item.tipo)">
                                                                {{ item.limite }}</td>
                                                            <td width="150px" :style="changeBackgroundColor(item.tipo)">
                                                                {{ item.precio }} $</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <br>
                            <v-row no-gutters>
                                <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                                <v-col cols="8" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                                    <v-row justify="center">
                                        <p>
                                            <br>
                                            ¡Regístrate ahora y
                                            únete a la revolución de las criptomonedas!
                                            <br>
                                            <br>
                                        </p>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <!-- <div v-if="width < 375">
                        <VideoPlayer src="video_intro.mp4">
                            <template v-slot:controls="{ togglePlay, toggleMute, playing, videoMuted }">
                                <div class="videoplayer-controls">
                                    <button @click="togglePlay()">{{ playing ? "pause" : "play" }}</button>
                                    <button @click="toggleMute()">{{ videoMuted ? "unmute" : "mute" }}</button>
                                </div>
                            </template>
</VideoPlayer>
</div> -->
                            <!--  -->
                            <!-- <v-col cols="12"> -->
                            <!-- <VideoPlayer class="videoplayer" src="video_intro.mp4" :muted="false" :autoplay="false"
                            :controls="true" :loop="false" poster="require('../../assets/logo.jpg')" @play="onPlayerPlay"
                            @pause="onPlayerPause" @ended="onPlayerEnded" @loadeddata="onPlayerLoadeddata"
                            @waiting="onPlayerWaiting" @playing="onPlayerPlaying" @timeupdate="onPlayerTimeupdate"
                            @canplay="onPlayerCanplay" @canplaythrough="onPlayerCanplaythrough"
                            @statechanged="playerStateChanged">
                         </VideoPlayer> -->
                            <!-- </v-col> -->
                            <!-- <div v-if="width >= 600">
                            <VideoPlayer class="videoplayer" src="video_intro.mp4" :muted="false" :autoplay="false"
                                :controls="true" :loop="false" poster="require('../../assets/logo.jpg')"
                                @play="onPlayerPlay" @pause="onPlayerPause" @ended="onPlayerEnded"
                                @loadeddata="onPlayerLoadeddata" @waiting="onPlayerWaiting" @playing="onPlayerPlaying"
                                @timeupdate="onPlayerTimeupdate" @canplay="onPlayerCanplay"
                                @canplaythrough="onPlayerCanplaythrough" @statechanged="playerStateChanged">
                            </VideoPlayer>
                        </div> -->
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-container>
</template>
<script>
import * as Prices from '@/data/prices.js'
// import User from '../models/user';
//import auth from "@/auth/auth"
// import VideoPlayer from '../../components/utils/VideoPlayer.vue'

export default {
    name: 'IndexComponent',
    components: {
        // VideoPlayer
    },
    data() {
        return {
            // Table
            headers: [
                { text: "Tipo", value: "tipo", align: "center" },
                { text: "Periodo", value: "periodo", align: "center" },
                { text: "Bots", value: "bots", align: "center" },
                { text: "Limite Inversión", value: "limite", align: "center" },
                { text: "Precio", value: "precio", align: "center" },
            ],
            items: []
            // videoOptions: {
            //     autoplay: true,
            //     controls: true,
            //     sources: [
            //         {
            //             src:
            //                 '../../assets/video_intro.mp4',
            //             type: 'video/mp4'
            //         }
            //     ]
            // }
        };
    },
    mounted() {
        // console.log(Prices.prices.items)
        this.items = Prices.prices.items
        if (this.loggedIn) {
            this.$router.push('/home');
        }
    },
    computed: {
        videoElement() {
            return this.$refs.video;
        },
        itemsWithIndex() {
            return this.items.map((item, index) => ({ ...item, index: index }))
        }
    },
    created() {

    },
    methods: {
        changeBackgroundColor(tipo) {
            //Get the decimal part of the number 
            // decimals = valueOfDay - Math.floor(valueOfDay);

            switch (tipo) {
                case 'Basic': return 'text-align: center;background-color: #b1edac;color: black'
                case 'Medium': return 'text-align: center;background-color: #acbaed;color: black'
                case 'Premium': return 'text-align: center;background-color: gold;color: black'

            }
        },
        onPlayerPlay({ event, player }) {
            console.log(event.type);
            player.setPlaying(true);
        },
        onPlayerPause({ event, player }) {
            console.log(event.type);
            player.setPlaying(false);
        },
        onPlayerEnded({ event, player }) {
            console.log(event.type);
            player.setPlaying(false);
        },
        onPlayerLoadeddata({ event }) {
            console.log(event.type);
        },
        onPlayerWaiting({ event }) {
            console.log(event.type);
        },
        onPlayerPlaying({ event }) {
            console.log(event.type);
        },
        onPlayerTimeupdate({ event }) {
            this.time = event.target.currentTime;
            console.log({ event: event.type, time: event.target.currentTime });
        },
        onPlayerCanplay({ event }) {
            console.log(event.type);
        },
        onPlayerCanplaythrough({ event }) {
            console.log(event.type);
        },
        playerStateChanged({ event }) {
            console.log(event.type);
        },
    }
}
</script>