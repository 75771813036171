<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-row no-gutters>
                            <v-row no-gutters>
                                <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                                <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                    <v-card class="pa-2">
                                        <v-data-table :headers="headers" :items="items" :search="search">
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-icon small class="mr-2"
                                                    @click="handleClick(item, 'pay')">mdi-play</v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="w-auto" v-show="isOpenModalidad">
            <MetodoPago ref="modalidad" @selection="receiveEmit" />
        </div>
        <div class="w-auto" v-show="isOpenModal">
            <CriptoModal ref="modalCripto" />
        </div>
        <div class="w-auto" v-show="isOpenModal">
            <PayModal ref="modal" />
        </div>
        
    </v-container>
</template>

<script>
import UserService from '../../services/user.service';
import EventBus from "../../common/EventBus";
// import URL_COINBASE from '../../services/direction';

import MetodoPago from '@/components/utils/SelectModalidadComponent.vue'
import CriptoModal from '@/components/utils/CriptoModalComponent.vue'
import PayModal from '@/components/utils/PayModalComponent.vue'


export default {
    name: 'SuscripcionMenuComponent',
    components: {
        MetodoPago,
        CriptoModal,
        PayModal,
    },
    data() {
        return {
            idBot: '',
            title: "TIPOS DE SUSCRIPCIÓN",
            initDate: "",
            startPrice: "",
            currentPrice: "",
            profits: "",
            fees: "",
            // Table
            headers: [
                { text: "Tipo", value: "nivel", align: "center" },
                { text: "Nombre", value: "name", align: "center" },
                { text: "Bots", value: "bots", align: "center" },
                { text: "Meses", value: "meses", align: "center" },
                { text: "Precio / $", value: "precio", align: "center" },
                { text: 'Actions', value: 'actions', sortable: false, align: "center" }
            ],
            items: [],
            right: null,
            search: '',
            start: false,
            colors: "success",
            msgBtn: '',
            isOpenModal: false,
            isOpenModalidad: false,
            selected: ''
        };
    },
    computed: {},
    created() {
    },
    mounted() {
        UserService.getTypes().then(
            response => {
                // console.log(response.data)
                this.loadData(response.data);
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                EventBus.dispatch("logout");
            }
        );
    },
    methods: {
        loadData(data) {
            this.items = data;
        },
        handleClick(value, action) {
            switch (action) {
                case 'pay':
                    // console.log(value)
                    this.selected = value
                    // console.log(this.selected)
                    this.openMenuPay(value)
                    // console.log(URL_COINBASE.URL_COINBASE)
                    // console.log(value.productId)
                    // window.location.href = URL_COINBASE.URL_COINBASE + value.productId
                    // window.open(URL_COINBASE.URL_COINBASE + value.productId, '_blank');
                    // UserService.getLinkPay(this.$store.state.auth.user.email, value.id).then(
                    //     response => {
                    //         console.log(URL_COINBASE)
                    //         // console.log(response.paymentIntent)
                    //         // window.location.href = response.paymentIntent
                    //     },
                    //     error => {
                    //         this.content =
                    //             (error.response && error.response.data && error.response.data.message) ||
                    //             error.message ||
                    //             error.toString();
                    //         // EventBus.dispatch("logout");
                    //     }
                    // );
                    break;
            }
        },
        receiveEmit(value) {
            // alert('Hello ' + value + '!');
            this.$refs.modalidad.closeModal()
            if (value === 'cripto') {
                // console.log('cripto')
                this.openModalCripto()
            } else {
                // console.log('amazon')
                this.openModalAmazon()
            }
        },
        openMenuPay() {
            this.$refs.modalidad.openModal()
        },
        openModalCripto() {
            this.$refs.modalCripto.openModal()
            this.$refs.modalCripto.idSuscripcion = this.selected.id
            this.$refs.modalCripto.tituloSuscripcion = this.selected.name
            this.$refs.modalCripto.tipo = this.selected.nivel
            this.$refs.modalCripto.bots = this.selected.bots
            this.$refs.modalCripto.meses = this.selected.meses
            this.$refs.modalCripto.precio = this.selected.precio
            // setTimeout(() => this.$refs.modal.chargeButton(), 500);

        },
        openModalAmazon() {
            this.$refs.modal.openModal()
            this.$refs.modal.idSuscripcion = this.selected.id
            this.$refs.modal.tituloSuscripcion = this.selected.name
            this.$refs.modal.tipo = this.selected.nivel
            this.$refs.modal.bots = this.selected.bots
            this.$refs.modal.meses = this.selected.meses
            this.$refs.modal.precio = this.selected.precio
            setTimeout(() => this.$refs.modal.chargeButton(), 500);

        },
    }
}
</script>