<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="500" class="options-container">
                                <v-card title="Pagar Suscripción." class="mx-auto">
                                    <v-card-text class="text-center">
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">SUSCRIPCIÓN {{ this.tituloSuscripcion
                                                }}</strong>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">NIVEL: {{ this.tipo }}</strong>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">BOTS: {{ this.bots }}</strong>
                                        </p>
                                        <br>
                                        <div v-if="this.meses < 2">
                                            <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                                <strong style="font-weight: bold;">DURACIÓN: {{ this.meses }}
                                                    MES</strong>
                                            </p>
                                        </div>
                                        <div v-else>
                                            <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                                <strong style="font-weight: bold;">DURACIÓN: {{ this.meses }}
                                                    MESES</strong>
                                            </p>
                                        </div>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">PRECIO: {{ this.precio }} €</strong>
                                        </p>
                                        <br>
                                        <div id="AmazonPayButton"></div>

                                    </v-card-text>

                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
        </div>
    </transition>

</template>

<script>
// import * as Amazon from '@/services/config.js'
const Amazon = require('@/services/config.js');

export default {
    name: 'PayModalComponent',
    data: function () {
        return {
            showModal: true,
            idSuscripcion: '',
            tituloSuscripcion: '',
            tipo: '',
            bots: '',
            meses: '',
            precio: '',

            dialog: false,
            advertising: true,
            performance: true,
            cookieDuration: 30,
            cookieValue: 'accepted',
            cookieConsent: false
        }
    },
    computed: {

    },
    methods: {
        openModal() {
            this.dialog = true
        },

        decline() {
            // this.toggleScroll(true)
            // // this.emit("toggleScroll", true);
            // // this.emit("close");
        },
        chargeButton() {
            window.amazon.Pay.renderButton('#AmazonPayButton', {
                // set checkout environment
                merchantId: Amazon.default.amazonPay.merchantId,
                createCheckoutSession: {
                    // url: 'http://localhost:8081/api/auth/createcheckoutsession?idSuscripcion=' + this.idSuscripcion
                    url: Amazon.default.amazonPay.endpoint.CreateCheckOut + this.idSuscripcion
                },
                sandbox: Amazon.default.amazonPay.sandbox, // dev environment
                ledgerCurrency: Amazon.default.amazonPay.ledgerCurrency, // Amazon Pay account ledger currency
                checkoutLanguage: Amazon.default.amazonPay.checkoutLanguage, // render language
                productType: Amazon.default.amazonPay.productType, // checkout type
                placement: Amazon.default.amazonPay.placement, // button placement
                buttonColor: Amazon.default.amazonPay.buttonColor
                // merchantId: 'A2MILJ3N89DP04',
                // createCheckoutSession: {
                //     url: 'http://localhost:8081/api/auth/createcheckoutsession?idSuscripcion=' + this.idSuscripcion
                // },
                // sandbox: true, // dev environment
                // ledgerCurrency: 'USD', // Amazon Pay account ledger currency
                // checkoutLanguage: 'en_US', // render language
                // productType: 'PayOnly', // checkout type
                // placement: 'Checkout', // button placement
                // buttonColor: 'Gold'
            });
        },
    },
    mounted() {
        // console.log(Amazon.default)
    }
}
</script>