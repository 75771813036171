const Amazon = require('@/services/config.js');

let price_eur_usd = Amazon.default.price_eur_usd

export let prices = {
    items: [
        {
            tipo: 'Basic',
            periodo: 'Mensual',
            bots: 1,
            limite: 500,
            precio: Math.round((6 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Basic',
            periodo: 'Anual',
            bots: 1,
            limite: 500,
            precio: Math.round((60 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Basic',
            periodo: 'Mensual',
            bots: 5,
            limite: 500,
            precio: Math.round((24 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Basic',
            periodo: 'Anual',
            bots: 5,
            limite: 500,
            precio: Math.round((230 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Medium',
            periodo: 'Mensual',
            bots: 1,
            limite: 1500,
            precio: Math.round((20 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Medium',
            periodo: 'Anual',
            bots: 1,
            limite: 1500,
            precio: Math.round((195 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Medium',
            periodo: 'Mensual',
            bots: 5,
            limite: 1500,
            precio: Math.round((80 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Medium',
            periodo: 'Anual',
            bots: 5,
            limite: 1500,
            precio: Math.round((770 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Premium',
            periodo: 'Mensual',
            bots: 1,
            limite: 'Sin Limite',
            precio: Math.round((50 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Premium',
            periodo: 'Anual',
            bots: 1,
            limite: 'Sin Limite',
            precio: Math.round((480 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Premium',
            periodo: 'Mensual',
            bots: 5,
            limite: 'Sin Limite',
            precio: Math.round((200 * price_eur_usd) * 100) / 100 
        },
        {
            tipo: 'Premium',
            periodo: 'Anual',
            bots: 5,
            limite: 'Sin Limite',
            precio: Math.round((1920 * price_eur_usd) * 100) / 100 
        },
    ]
};
