import address from '@/services/direction.js';

let config = {
    "amazonPay": {
        "sandbox": true,
        "merchantId": "A2MILJ3N89DP04",
        "clientId": "amzn1.application-oa2-client.87a578cbaee54d6eb5b1f174cfa4bc0a",
        "endpoint": {
            "CreateCheckOut": address.URL + 'createcheckoutsession?idSuscripcion=',
            // "GetOrderReferenceDetails": "http://localhost:8080/api/ext/amazon-pay/GetOrderReferenceDetails",
            // "SetOrderReferenceDetails": "http://localhost:8080/api/ext/amazon-pay/SetOrderReferenceDetails",
            // "ConfirmOrderReference": "http://localhost:8080/api/ext/amazon-pay/ConfirmOrderReference",
            // "CancelOrderReference": "http://localhost:8080/api/ext/amazon-pay/CancelOrderReference",
            // "Authorize": "http://localhost:8080/api/ext/amazon-pay/Authorize"
        },
        "storeName": "INNOVATIONS SOLITIONS AT LLC",
        "ledgerCurrency": 'USD', // Amazon Pay account ledger currency
        "checkoutLanguage": 'en_US', // render language
        "productType": 'PayOnly', // checkout type
        "placement": 'Checkout', // button placement
        "buttonColor": 'Gold',
        "authorization": {
            "asynchronous": true
        }
    },
    "price_eur_usd": 1.10
}

// let price = {
//     "price_eur_usd": 1.10
// }

export default config;
// module.exports = {
//     config,
//     price
//  }
// module.exports = config