<template>
  <v-container class="bg-surface-variant">
    <div class="d-flex flex-column mb-6 bg-surface-variant">
      <v-row class="text-center">
        <v-col cols="12">
          <!-- <v-img :src="require('../../assets/logo_vue.svg')" class="my-3" contain height="200" /> -->
          <v-img :src="require('../../assets/logo.jpg')" class="my-3" contain height="200" />
        </v-col>
        <br>
        <v-row class="text-center">
          <v-col cols="12">
            <br>
            <v-card elevation="2" outlined shaped>
              <v-row class="text-center">
                <v-col cols="12" class="mb-4">
                  <br>
                  <h1 class="display-2 font-weight-bold mb-3">
                    {{ username }}
                  </h1>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <div v-if="!isMobile()">
                <v-row class="text-center">
                  <v-col cols="6" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <br>
                      <h3>Tipo de suscripción: {{ userType }}</h3>
                      <p class="subheading font-weight-regular">
                        <br>Bots suscripción: {{ botsType }}
                        <br>Bots creados: {{ botsCreated }}
                        <br>Fin suscripción: {{ endSubs }}
                      </p>
                      <!-- <a href="https://commerce.coinbase.com/checkout/28f1d543-48bc-4288-9ea5-db20dcd48426">
                        <span>Pay with crypto</span>
                      </a> -->
                    </v-card>
                  </v-col>
                  <v-col cols="6" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <Bar id="my-chart-id" v-if="loaded" :data="chartData" :style="myStyles" />
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row class="text-center">
                  <v-col cols="12" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <br>
                      <h3>Tipo de suscripción: {{ userType }}</h3>
                      <p class="subheading font-weight-regular">
                        <br>Bots suscripción: {{ botsType }}
                        <br>Bots creados: {{ botsCreated }}
                        <br>Fin suscripción: {{ endSubs }}
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col cols="12" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <Bar id="my-chart-id" v-if="loaded" :data="chartData" :style="myStyles" />
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import User from '../../models/user';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
// import Client from '@amazonpay/amazon-pay-api-sdk-nodejs/src/client.js'
// import ButtonAmazon from '@/components/amazon/ButtonAmazonComponent.vue'

// const Client = require('@amazonpay/amazon-pay-api-sdk-nodejs');

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'HomeComponent',
  components: {
    Bar,
    // ButtonAmazon
  },
  data() {
    return {
      title: 'HOME',
      user: new User('', '', ''),
      content: '',
      username: '',
      userType: '',
      botsType: '',
      botsCreated: '',
      endSubs: '',
      totalInvertido: '',
      totalBeneficios: '',
      totalComisiones: '',
      submitted: false,
      successful: false,
      message: '',
      valid: '',
      isShow: false,
      msgError: '',
      types: "success",
      dialog: false,
      // Chart
      loaded: false,
      chartData: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril',
          'Mayo', 'Junio', 'Julio', 'Agosto',
          'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [{
          label: 'Ganancias Mensuales',
          data: [],
          backgroundColor: '#ffffff',
        }]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      myClient: ''
    };
  },
  created() {
    // if (this.$store.user === "") {
    //   this.$store.dispatch("auth/logout");
    //   this.$router.push("/");
    // }
    // this.width = window.innerWidth
  },
  computed: {
    currentUser() {
      return this.user;
    },
    myStyles() {
      return {
        height: '${/* mutable height */}px',
        position: 'relative'
      }
    }
  },
  mounted() {
    // console.log(window.amazon.Pay.renderButton)

    // window.amazon.Pay.renderButton('#AmazonPayButton', {
    //   // set checkout environment
    //   merchantId: 'merchant_id',
    //   publicKeyId: 'SANDBOX-xxxxxxxxxx',
    //   ledgerCurrency: 'USD',
    //   // customize the buyer experience
    //   checkoutLanguage: 'en_US',
    //   productType: 'PayAndShip',
    //   placement: 'Cart',
    //   buttonColor: 'Gold',
    //   estimatedOrderAmount: { "amount": "109.99", "currencyCode": "USD" },
    //   // configure Create Checkout Session request
    //   createCheckoutSessionConfig: {
    //     payloadJSON: 'payload', // payload generated in step 2
    //     signature: 'xxxx', // signature generatd in step 3
    //     algorithm: 'AMZN-PAY-RSASSA-PSS-V2'
    //   }
    // });
    this.loaded = false
    UserService.getHomeInfo(this.$store.state.auth.user.email).then(
      response => {
        this.username = response.data.userName;
        this.userType = response.data.userType;
        this.botsType = response.data.botsType;
        this.botsCreated = response.data.botsCreated;
        this.endSubs = response.data.end_time;
        this.totalInvertido = response.data.totalInvertido;
        this.totalBeneficios = response.data.beneficios;
        this.totalComisiones = response.data.comisiones;
        for (let index = 0; index < this.totalBeneficios.length; index++) {
          this.chartData.datasets[0].data.push(this.totalBeneficios[index].beneficios)
        }
        this.loaded = true
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        EventBus.dispatch("logout");
      }
    );
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    getCheckout() {
      // window.location.href ='https://commerce.coinbase.com/checkout/28f1d543-48bc-4288-9ea5-db20dcd48426'
      // console.log(Client)
      UserService.getAmazonButton().then(
        response => {
          console.log(response.payload)
          const amazonPayButton = window.amazon.Pay.renderButton('#AmazonPayButton', {
            // // set checkout environment
            // merchantId: 'A2MILJ3N89DP04',
            // // publicKeyId: 'SANDBOX-AGPD6CYZUSHLB4FBZGDF5WTL',
            // ledgerCurrency: 'USD',
            // // customize the buyer experience
            // checkoutLanguage: 'en_US',
            // productType: 'PayOnly',
            // placement: 'Checkout',
            // buttonColor: 'Gold',
            // estimatedOrderAmount: { "amount": "109.99", "currencyCode": "USD" },
            // createCheckoutSession: {
            //     url: 'http://localhost:8081/api/auth/createcheckoutsession'
            // },
            // // configure Create Checkout Session request
            // createCheckoutSessionConfig: {
            //   payloadJSON: response.payload, // string generated in step 2
            //   signature: response.signature, // signature generated in step 3
            //   algorithm: 'AMZN-PAY-RSASSA-PSS-V2'
            // },
            // sandbox: true

            merchantId: 'A2MILJ3N89DP04',
            createCheckoutSession: {
              url: 'http://localhost:8081/api/auth/createcheckoutsession?email=' + this.$store.state.auth.user.email
            },
            sandbox: true, // dev environment
            ledgerCurrency: 'USD', // Amazon Pay account ledger currency
            checkoutLanguage: 'en_US', // render language
            productType: 'PayOnly', // checkout type
            placement: 'Checkout', // button placement
            buttonColor: 'Gold'
          });
          console.log(amazonPayButton)
        },
        error => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // EventBus.dispatch("logout");
        }
      );
    }
  }
}
</script>
