<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                            <!-- <v-divider class="mx-4"></v-divider> -->
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0">
                            </v-col>
                            <v-col cols="10" xs="8" sm="8" md="10" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="10" xs="8" sm="8" md="10" class="flex-grow-0 flex-shrink-0">
                                            <v-row no-gutters style="flex-wrap: nowrap;">
                                                <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                                    <p class="subheading font-weight-regular">
                                                        <br>Dirección:
                                                        <br>
                                                        <br>Calle: {{ calle }}
                                                        <br>Ciudad: {{ ciudad }}
                                                        <br>Pais: {{ pais }}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters style="flex-wrap: nowrap;">
                                                <v-col cols="8" xs="9" sm="9" md="9" class="flex-grow-0 flex-shrink-0">
                                                    <p class="subheading font-weight-regular">
                                                        <!-- <br> -->
                                                        <br>Metodo de pago:
                                                        <br>
                                                        <br>Tarjeta: {{ tarjeta }}
                                                    </p>
                                                </v-col>
                                                <v-col cols="3" xs="3" sm="3" md="3" class="flex-grow-0 flex-shrink-0">
                                                    <div id="AmazonPayButton">
                                                        <br>
                                                        <v-btn color="secondary" rounded
                                                            @click="chargeButton">
                                                            CAMBIAR TARJETA
                                                        </v-btn></div>
                                                </v-col>
                                            </v-row>
                                            <!-- <p class="subheading font-weight-regular">
                                                <br>Dirección:
                                                <br>
                                                <br>Calle: {{ calle }}
                                                <br>Ciudad: {{ ciudad }}
                                                <br>Pais: {{ pais }}
                                                <br>
                                                <br>Metodo de pago:
                                                <br>
                                                <br>Tarjeta: {{ tarjeta }}


                                            </p> -->
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0">
                            </v-col>
                            <v-col cols="10" xs="8" sm="8" md="10" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="10" xs="8" sm="8" md="10" class="flex-grow-0 flex-shrink-0">
                                            <v-data-table :headers="headers" :items="items" class="elevation-1"
                                                hide-default-footer></v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="3" xs="0" sm="0" md="5"> </v-col>
                            <v-col cols="6" xs="2" sm="2" md="2">
                                <v-row no-gutters justify="center">
                                    <br>
                                    <br>Total: {{ total }} $
                                </v-row>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="3" xs="0" sm="0" md="5"> </v-col>
                            <v-col cols="6" xs="2" sm="2" md="2">
                                <v-row no-gutters justify="center">
                                    <v-btn color="success" @click="postConfimar">
                                        CONFIRMAR
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <br>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
// import User from '../models/user';

export default {
    name: 'SuscripcionComponent',
    components: {

    },
    data() {
        return {
            title: 'CHECKOUT',
            idSuscripcion: '',
            session: '',
            statusAPI: '',
            calle: '',
            ciudad: '',
            pais: '',
            tarjeta: '',
            // Table
            headers: [
                { text: "Tipo", value: "nivel", align: "center" },
                { text: "Nombre", value: "name", align: "center" },
                { text: "Bots", value: "bots", align: "center" },
                { text: "Meses", value: "meses", align: "center" },
                { text: "Precio", value: "precio", align: "center" }
            ],
            items: [],
            total: ''
        };
    },
    computed: {},
    mounted() {
        // console.log(this.$route.query) // outputs 'yay'
        // let keys = Object.keys(this.$route.query)
        let values = Object.values(this.$route.query)
        // console.log(keys.length)
        // console.log(keys)
        // console.log(values)

        this.idSuscripcion = values[0]
        this.statusAPI = values[1]
        this.session = values[2]

        UserService.getType(this.idSuscripcion).then(
            response => {
                // console.log(response)
                this.items.push(response.data);
                this.total = this.items[0].precio
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                // EventBus.dispatch("logout");
            }
        );

        UserService.getCheckoutSession(this.session).then(
            response => {
                // console.log('Get CheckOut')
                // console.log(response)
                this.calle = response.billingAddress.addressLine1
                this.ciudad = response.billingAddress.city
                this.pais = response.billingAddress.countryCode
                this.tarjeta = response.paymentPreferences[0].paymentDescriptor
                if (response.statusDetails.state === 'Open') {
                    console.log('Entra Open')

                } else {
                    console.log(response.statusDetails.state)
                }

            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(error)
                // this.messages.value.push(error);
            }
        );
    },
    methods: {
        postConfimar() {
            UserService.postUpdateCheckoutSession(this.session, this.idSuscripcion).then(
                response => {
                    // console.log('Update CheckOut')
                    // console.log(response)
                    window.location.href = response.webCheckoutDetails.amazonPayRedirectUrl
                    // window.open(response.webCheckoutDetails.amazonPayRedirectUrl);
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(error)
                    // this.messages.value.push(error);
                }
            );
        },
        paySubscription() {
            UserService.chekPeriodSus(this.$store.state.auth.user.email).then(
                response => {
                    if (response) {
                        // console.log(response.data)
                        if (response.data > 15) {
                            this.msgError = 'Todavia le faltan ' + response.data + ' días para que finalice la suscripcón. '
                                + 'Si quiere renovar la suscripción, abra un ticket'
                            // console.log(response)
                            this.types = 'success'
                            this.isShow = true
                        } else {
                            this.$router.push("/menu_suscripcion");
                        }

                    }

                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    EventBus.dispatch("logout");
                }
            );


        },
        paySubs() {
            this.$router.push("/formpay");
            // this.$router.push("/formpay");
        },
        // paySubs() {
        //     UserService.getCheckout(this.$store.state.auth.user).then(
        //         response => {
        //             console.log(response)
        //             // window.location.href = response.data
        //         },
        //         error => {
        //             this.content =
        //                 (error.response && error.response.data && error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();
        //             EventBus.dispatch("logout");
        //         }
        //     );
        // },
        closeAlert() {
            this.isShow = false
        },
        chargeButton() {
            window.amazon.Pay.changePaymentMethod({
                amazonCheckoutSessionId: this.session
            });
        },

    }
}
</script>