<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">

            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row no-gutters>
                            <v-col cols="2">
                            </v-col>
                            <v-col cols="8">
                                <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                    <AdminMenu />
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ titleOrders }}</v-card-title>
                        </v-row>
                        <vDivider></vDivider>
                        <v-row no-gutters>
                            <v-col cols="2"></v-col>
                            <!-- <v-col cols="3" class="flex-grow-0 flex-shrink-0">
                            <v-card elevation="2" class="pa-4" outlined>
                                <v-list dense nav>
                                    <v-list-item v-for="item in items" :key="item.title"
                                        @click="menuActionClick(item.action)">
                                        <v-list-item-icon>
                                            <v-icon>{{ item.icon }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col> -->
                            <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                                <v-row>
                                    <v-col cols="6">
                                        
                                    </v-col>
                                    <v-col cols="3"></v-col>
                                    <v-col cols="3">
                                        <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                            <v-card-title>
                                                <v-list dense nav>
                                                    <v-list-item v-for="item in itemsActions" :key="item.title"
                                                        @click="menuActionClick(item.action)">
                                                        <v-list-item-icon>
                                                            <v-icon>{{ item.icon }}</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                
                            </v-col>
                        </v-row>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="8" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <v-data-table :headers="headers" :items="items" :search="search" class="elevation-1"
                                        v-model="selected" @click:row="dblclickRow(items, $event)"></v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="dialog" max-width="800">
                <v-card>
                    <v-card-title class="text-h5">
                        INFORMACION PAGO
                    </v-card-title>

                    <v-card-text>
                        <v-data-table :headers="headersDialog" :items="itemsDialog" :search="search"
                            class="elevation-1"></v-data-table>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialog = false">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>
import AdminMenu from "../../components/admin/AdminMenuComponent.vue"
import Bot from "../../models/bot"
import UserService from '../../services/user.service'
import EventBus from "../../common/EventBus"

export default {
    name: 'AdminPagos',
    components: {
        AdminMenu
    },
    data() {
        return {
            idBot: '',
            bot: new Bot(),
            titleOrders: "",
            // Table
            headers: [
                { text: "Usuario", value: "user.email", align: "center" },
                { text: "Inicio", value: "start_time", align: "center" },
                { text: "Fin", value: "end_time", align: "center" },
                { text: "Cantidad", value: "quantity", align: "center" },
                { text: "Pagado", value: "payed", align: "center" }
            ],
            items: [],
            right: null,
            search: '',
            start: false,
            colors: "success",
            msgBtn: '',
            selected: [],
            idCompra: '',
            clicks: '0',
            dialog: false,
            // Table
            headersDialog: [
                { text: "Usuario", value: "user.email", align: "center" },
                { text: "Inicio", value: "start_time", align: "center" },
                { text: "Fin", value: "end_time", align: "center" },
                { text: "Cantidad", value: "price", align: "center" },
                { text: "Pagado", value: "feeAmount", align: "center" }
            ],
            itemsDialog: [],
            venta: '',
            itemsActions: [
                {
                    title: "Crear Pago",
                    icon: "mdi-view-dashboard",
                    action: "newPay"
                }
            ],
        };
    },
    computed: {},
    created() { },
    mounted() {
        UserService.getAdminPays().then(
            response => {
                // console.log(response.data)
                this.items = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                EventBus.dispatch("logout");
            }
        );
    },
    methods: {
        menuActionClick(action) {
            if (action === "newPay") {
                this.$router.push('/admin_pagos_save');
                // if (this.apiError === false) {
                //     UserService.isAccount(this.$store.state.auth.user.email).then(
                //         response => {
                //             this.content = response.data;
                //             this.$router.push('/newbot');
                //         },
                //         error => {
                //             this.content =
                //                 (error.response &&
                //                     error.response.data &&
                //                     error.response.data.message) ||
                //                 error.message ||
                //                 error.toString();
                //         }
                //     );
                // } else {
                //     this.msgError = 'Verifique su API.'
                //     this.isShow = true
                // }
            }
        },
        dblclickRow(row, event) {
            // console.log(event.idVenta)
            this.clicks++
            if (this.clicks === 1) {          // the first click in .2s
                var self = this;

                setTimeout(function () {
                    switch (self.clicks) {     // check the event type
                        case 2:
                            self.openDialog(event.idCompra, event.idVenta)
                            break;
                    }
                    self.clicks = 0;               // reset the first click
                }, 200);                              // wait 0.2s
            }
        },
        openDialog(idCompra, idVenta) {
            this.dialog = true
            if (idVenta === null || idVenta === undefined) {
                this.venta = -1
            } else {
                this.venta = idVenta
            }
            UserService.getAdminTrade(idCompra, this.venta).then(
                response => {
                    // console.log(response.data)
                    this.itemsDialog = response.data
                    // console.log(this.itemsDialog)
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    // EventBus.dispatch("logout");
                }
            );
        }
    }
}
</script>

<style scoped>
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
    background-color: blue;
}
</style>