<template>
  <v-container class="bg-surface-variant">
    <div class="d-flex flex-column mb-6 bg-surface-variant">

      <v-row no-gutters style="flex-wrap: nowrap;">
        <v-col>
          <v-card elevation="2" outlined shaped>
            <v-row no-gutters>
              <v-col cols="2">
              </v-col>
              <v-col cols="8">
                <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                  <AdminMenu />
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-card-title class="text-h5 text-center">{{ titleOrders }}</v-card-title>
            </v-row>

            <v-row no-gutters>
              <v-col cols="0" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
              <v-col cols="12" xs="0" sm="0" md="8" class="flex-grow-0 flex-shrink-0">
                <v-card elevation="2" outlined shaped>
                  <br>
                  <v-row no-gutters>

                    <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Fecha Inicio:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ initDate }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Precio Inicial:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ startPrice }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br>
                  <vDivider></vDivider>
                  <br>
                  <v-row no-gutters>
                    <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Precio Actual:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ currentPrice }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Comisiones:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ fees }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br>
                  <vDivider></vDivider>
                  <br>
                  <v-row no-gutters>
                    <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Beneficios:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ profits }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                    <!-- <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                        <v-row no-gutters justify="center">
                          <v-card>
                            <v-btn :color="colors" @click="statusBot">{{ msgBtn }}</v-btn>
                          </v-card>
                        </v-row>
  
                      </v-col> -->
                  </v-row>
                  <br>
                </v-card>
              </v-col>
            </v-row>
            <vDivider></vDivider>
            <br>
            <v-row no-gutters>
              <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
              <v-col cols="8" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                <v-card class="pa-2">
                  <v-data-table :headers="headers" :items="items" :search="search" class="elevation-1"
                    v-model="selected" @click:row="dblclickRow(items, $event)"></v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="800">
        <v-card>
          <v-card-title class="text-h5">
            INFORMACION TRADE
          </v-card-title>

          <v-card-text>
            <v-data-table :headers="headersDialog" :items="itemsDialog" :search="search"
              class="elevation-1"></v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import AdminMenu from "../../components/admin/AdminMenuComponent.vue"
import Bot from "../../models/bot"
import UserService from '../../services/user.service'
import EventBus from "../../common/EventBus"

export default {
  name: 'AdminTrades',
  components: {
    AdminMenu
  },
  data() {
    return {
      idBot: '',
      bot: new Bot(),
      titleOrders: "",
      pair1: "",
      pair2: "",
      initDate: "",
      startPrice: "",
      currentPrice: "",
      profits: "",
      fees: "",
      // Table
      headers: [
        { text: "Tipo", value: "typeCompra", align: "center" },
        { text: "Fecha Compra", value: "fechaCompra", align: "center" },
        // { text: "Cantidad Comprado", value: "amountCompra", align: "center" },
        { text: "Precio Compra", value: "precioCompra", align: "center" },
        { text: "Tipo", value: "typeVenta", align: "center" },
        { text: "Fecha Venta", value: "fechaVenta", align: "center" },
        // { text: "Cantidad Vendido", value: "amountVenta", align: "center" },
        { text: "Precio Venta", value: "precioVenta", align: "center" },
        { text: "Volumen", value: "amountCompra", align: "center" },
      ],
      items: [],
      right: null,
      search: '',
      start: false,
      colors: "success",
      msgBtn: '',
      selected: [],
      idCompra: '',
      clicks: '0',
      dialog: false,
      // Table
      headersDialog: [
        { text: "Tipo", value: "type", align: "center" },
        { text: "Fecha Compra", value: "timestamp", align: "center" },
        { text: "Volumen", value: "originalAmount", align: "center" },
        { text: "Precio Compra", value: "price", align: "center" },
        { text: "Comisiones", value: "feeAmount", align: "center" }
      ],
      itemsDialog: [],
      venta: ''
    };
  },
  computed: {
    itemsWithIndex() {
      return this.items.map((item, index) => ({ ...item, index: index }))
    }
  },
  created() {
  },
  mounted() {
    if (this.$route.params.idBot !== null) {
      this.idBot = this.$route.params.idBot
    }
    UserService.getStatusBot(this.idBot).then(
      response => {
        if (response.data === 'NOT INIT') {
          UserService.getBot(this.idBot).then(
            response => {
              this.bot = response.data
            }
          );

        } else {
          this.loadData(response.data);
        }

      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        EventBus.dispatch("logout");
      }
    );
  },
  methods: {
    loadData(data) {
      this.bot = data.bot;
      this.titleOrders = this.bot.pair;
      let arrayPairs = this.bot.pair.split("-");
      // console.log(arrayPairs[0])
      this.pair1 = arrayPairs[0];
      this.pair2 = arrayPairs[1];
      this.initDate = data.start_time
      this.startPrice = data.start_price
      this.currentPrice = data.currentPrice
      this.fees = data.fees
      this.profits = data.profits
      this.getOrders();
    },
    getOrders() {
      UserService.getAdminTradesBot(this.idBot).then(
        response => {
          // console.log(response.data)
          this.items = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          EventBus.dispatch("logout");
        }
      );
    },
    dblclickRow(row, event) {
      // console.log(event.idVenta)
      this.clicks++
      if (this.clicks === 1) {          // the first click in .2s
        var self = this;

        setTimeout(function () {
          switch (self.clicks) {     // check the event type
            case 2:
              self.openDialog(event.idCompra, event.idVenta)
              break;
          }
          self.clicks = 0;               // reset the first click
        }, 200);                              // wait 0.2s
      }
    },
    openDialog(idCompra, idVenta) {
      this.dialog = true
      if (idVenta === null || idVenta === undefined) {
        this.venta = -1
      } else {
        this.venta = idVenta
      }
      UserService.getAdminTrade(idCompra, this.venta).then(
        response => {
          console.log(response.data)
          this.itemsDialog = response.data
          // console.log(this.itemsDialog)
        },
        error => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // EventBus.dispatch("logout");
        }
      );
    }
  }
}
</script>

<style scoped>
.mytable .v-table tbody tr:not(:last-child) {
  border-bottom: none;
  background-color: blue;
}
</style>