<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="500" class="options-container">
                                <v-card title="Pagar Suscripción." class="mx-auto">
                                    <v-card-text class="text-center">
                                        <br>
                                        <v-img :src="require('../../assets/wallet_tron.png')" class="my-3" contain
                                            height="200" />
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">WALLET: </strong>
                                            <strong v-on:focus="$event.target.select()" ref="myWallet"
                                                style="font-weight: bold;">{{ this.wallet }}</strong>
                                            <v-btn class="mx-2" fab dark small color="secondary" @click="copyClipboard">
                                                <v-icon dark>
                                                    mdi-clipboard-outline
                                                </v-icon>
                                            </v-btn>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold; color: red;">Atención!!</strong>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold; color: red">Revisar la red de la
                                                billetera.<br>
                                                Red de envío TRON. En observaciones / comentarios añadir el
                                                email del usuario registrado en nuestra plataforma.
                                            </strong>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">SUSCRIPCIÓN {{ this.tituloSuscripcion
                                                }}</strong>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">NIVEL: {{ this.tipo }}</strong>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">BOTS: {{ this.bots }}</strong>
                                        </p>
                                        <br>
                                        <div v-if="this.meses < 2">
                                            <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                                <strong style="font-weight: bold;">DURACIÓN: {{ this.meses }}
                                                    MES</strong>
                                            </p>
                                        </div>
                                        <div v-else>
                                            <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                                <strong style="font-weight: bold;">DURACIÓN: {{ this.meses }}
                                                    MESES</strong>
                                            </p>
                                        </div>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">PRECIO: {{ this.precio }} $</strong>
                                        </p>
                                        <br>
                                        <div id="AmazonPayButton"></div>

                                    </v-card-text>

                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
            <div class="w-auto">
                <DialogModal ref="modalDialog" />
            </div>
        </div>

    </transition>

</template>

<script>
import * as Address from '@/services/direction.js'
import DialogModal from '@/components/utils/DialogClipboardComponent.vue'


export default {
    name: 'CriptoModalComponent',
    components: {
        DialogModal
    },
    data: function () {
        return {
            showModal: true,
            idSuscripcion: '',
            tituloSuscripcion: '',
            tipo: '',
            bots: '',
            meses: '',
            precio: '',
            wallet: '',
            dialog: false
        }
    },
    computed: {

    },
    methods: {
        openModal() {
            this.dialog = true
        },

        closeAlert() {
            this.isShow = false
        },
        async copyClipboard() {
            try {
                await navigator.clipboard.writeText(this.wallet);
                // this.isShow = true
                this.$refs.modalDialog.openModal()
                this.$refs.modalDialog.msg = 'Se ha copiado la wallet en su portapapeles.'
                this.$refs.modalDialog.type = 'success'
                // alert('Copied');
            } catch ($e) {
                this.$refs.modalDialog.openModal()
                this.$refs.modalDialog.msg = 'Ocurrió un error al copiar la wallet al portapapeles.'
                this.$refs.modalDialog.type = 'error'
            }
        }

    },
    mounted() {
        // console.log(Address.WALLET)
        this.wallet = Address.WALLET
    }
}
</script>